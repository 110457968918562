import { I18nextContext, Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { useMedia } from '../hooks/media';
import { useMenuStore } from '../hooks/stores';
import lightingColors from '../styles/lighting';

import deFlag from '../svgs/flag-de.svg';
import enFlag from '../svgs/flag-us.svg';

export const MenuContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	height: '100%',
	flex: 1,
	justifyContent: 'flex-end',
	'& :not(:first-child)': {
		marginLeft: '3rem',
		'@media only screen and (max-width: 600px)': {
			marginLeft: 0,
		},
	},
	'@media only screen and (max-width: 600px)': {
		flexDirection: 'column',
		// marginTop: '2rem',
		justifyContent: 'center',
	},
});

const menuStyle = {
	fontWeight: 300,
	fontSize: '0.8rem',
	color: '#fff',
	textDecoration: 'none',

	'&:hover': {
		textDecoration: 'none',
	},
	'&:focus': {
		textDecoration: 'none',
	},
	'@media only screen and (max-width: 600px)': {
		marginTop: 8,
		marginBottom: 8,
		fontWeight: 700,
		fontSize: '1.4375rem',
	},
};

export const MenuHighlight = styled.span({
	color: lightingColors.highlight.orange,
	fontSize: '0.8rem',

	'@media only screen and (max-width: 600px)': {
		paddingLeft: '5px',
		verticalAlign: 'middle',
	},
});

const MenuLink = styled(Link)(menuStyle);
const ContactLink = styled.a(menuStyle);

const LanguageLink = styled.div({ ...menuStyle, cursor: 'pointer' });

export const LightingMenu: React.FC = observer(() => {
	const context = React.useContext(I18nextContext);
	const { changeLanguage } = useI18next();

	const { t } = useTranslation();
	const menuStore = useMenuStore();
	const media = useMedia();

	return (
		<MenuContainer onClick={media.small ? menuStore.onPressToggleMenu : undefined}>
			<MenuLink to="/bluetooth">{t('menu.bluetooth')}</MenuLink>
			<MenuLink to="/matter">
				{t('menu.matter')} <MenuHighlight>{t('menu.highlight')}</MenuHighlight>
			</MenuLink>
			<MenuLink to="/developers">{t('menu.developers')}</MenuLink>
			<MenuLink to="/blog">{t('menu.blog')}</MenuLink>
			{/* <MenuLink to="/#Contact">{t('menu.contact')}</MenuLink> */}
			<ContactLink href="mailto:info@thingos.io" target="_blank">
				{t('menu.contact')}
			</ContactLink>

			<LanguageLink
				onClick={e => {
					e.preventDefault();
					changeLanguage(context.language === 'de' ? 'en' : 'de');
				}}
				style={{ fontSize: '1.6rem', display: 'flex' }}
			>
				{/* {context.language === 'de' ? '🇩🇪' : '🇺🇸'} */}
				{context.language === 'de' ? (
					<img src={deFlag} style={{ minWidth: '1.3rem' }} alt="flag-de" />
				) : (
					<img src={enFlag} style={{ minWidth: '1.3rem' }} alt="flag-us" />
				)}
			</LanguageLink>
		</MenuContainer>
	);
});
