// TODO: export colors
// TODO: set colors as css variables
// TODO: add toggle capability that change the css variabels

export const theme = {
	background: {
		light: 'var(--background-light)',
		dark: 'var(--background-dark)',
		card: 'var(--background-card)',
	},
	highlight: {
		main: 'var(--highlight-main)',
		secondary: 'var(--highlight-secondary)',
		thingos: 'var(--highlight-thingos)',
	},

	white: 'var(--colors-white)',
	text: {
		primary: 'var(--text-primary)',
		secondary: 'var(--text-secondary)',
		hint: 'var(--text-hint)',
		disabled: 'var(--text-disabled)',
	},
	inverted: {
		text: {
			primary: 'var(--inverted-text-primary)',
			secondary: 'var(--inverted-text-secondary)',
			hint: 'var(--inverted-text-hint)',
			disabled: 'var(--inverted-text-disabled)',
		},
		background: {
			light: 'var(--inverted-background-light)',
			dark: 'var(--inverted-background-dark)',
		},
	},
};

export type ColorTheme = typeof theme;

export const lightingColors: ColorTheme = {
	background: {
		light: '#171635',
		dark: '#161333',
		card: '#11102B',
		// #31476e
	},
	highlight: {
		main: '#f99b43',
		secondary: '#f72580',
		thingos: '#204af7',
	},

	white: '#ffffff',
	text: {
		primary: 'hsla(0, 0%, 100%, 1.0)',
		secondary: 'hsla(0, 0%, 100%, 0.7)',
		hint: 'hsla(0, 0%, 100%, 0.5)',
		disabled: 'hsla(0, 0%, 100%, 0.5)',
	},
	inverted: {
		text: {
			primary: 'hsla(0, 0%, 0%, 0.87)',
			secondary: 'hsla(0, 0%, 0%, 0.54)',
			hint: 'hsla(0, 0%, 0%, 0.38)',
			disabled: 'hsla(0, 0%, 0%, 0.38)',
		},
		background: {
			light: '#31476e',
			dark: '#1e2b44',
		},
	},
};

export const thingosColors: ColorTheme = {
	background: {
		light: '#fff',
		dark: '#F5F8FC',
		card: '#fff',
	},
	highlight: {
		main: '#265ebf',
		secondary: '#fc984C',
		thingos: '#265ebf',
	},

	white: '#ffffff',
	text: {
		primary: 'hsla(218, 67%, 30%, 0.87)',
		secondary: 'hsla(218, 67%, 30%, 0.60)',
		hint: 'hsla(218, 67%, 30%, 0.38)',
		disabled: 'hsla(218, 67%, 30%, 0.38)',
	},
	inverted: {
		text: {
			primary: 'hsla(0, 0%, 100%, 1.0)',
			secondary: 'hsla(0, 0%, 100%, 0.7)',
			hint: 'hsla(0, 0%, 100%, 0.5)',
			disabled: 'hsla(0, 0%, 100%, 0.5)',
		},
		background: {
			light: '#31476e',
			dark: '#1e2b44',
		},
	},
};
