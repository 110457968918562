import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../Text';
import { Theme } from '../Theme';

const { theme } = Theme;

const FooterSiteMapContainerOuter = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	backgroundColor: theme.inverted.background.light,
});

const FooterSiteMapContainerInner = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	padding: '1rem 1rem 3rem 1rem',
	maxWidth: '60rem',
	'@media only screen and (max-width: 600px)': {
		flexDirection: 'column',
		alignItems: 'flex-start',
		maxWidth: '100%',
	},
});

const StyledLocalLink = styled(Link)({
	color: theme.inverted.text.secondary,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	textDecoration: 'none',
	'&:hover': {
		color: theme.inverted.text.primary,
		textDecoration: 'none',
	},
});

const StyledExternalLink = styled.a({
	color: theme.inverted.text.secondary,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	textDecoration: 'none',
	'&:hover': {
		color: theme.inverted.text.primary,
		textDecoration: 'none',
	},
});

const FooterSiteMapContainer: React.FC = props => {
	return (
		<FooterSiteMapContainerOuter>
			<FooterSiteMapContainerInner {...props}></FooterSiteMapContainerInner>
		</FooterSiteMapContainerOuter>
	);
};
interface FooterSiteMapLinkProps {
	to: string;
}
const FooterSiteMapLocalLink: React.FC<FooterSiteMapLinkProps> = props => {
	return (
		<Text.Footnote>
			<StyledLocalLink {...props}></StyledLocalLink>
		</Text.Footnote>
	);
};
const FooterSiteMapExternalLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> =
	props => {
		return (
			<Text.Footnote>
				<StyledExternalLink {...props}></StyledExternalLink>
			</Text.Footnote>
		);
	};

const FooterSiteMapColumn = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
});
const FooterSiteMapHeader = styled(Text.Subtitle2)({ color: Theme.theme.inverted.text.primary });

export const FooterSiteMap = {
	Container: FooterSiteMapContainer,
	Column: FooterSiteMapColumn,
	Header: FooterSiteMapHeader,
	ExternalLink: FooterSiteMapExternalLink,
	LocalLink: FooterSiteMapLocalLink,
	SocialLink: null,
};
