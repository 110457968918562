import * as React from 'react';
import styled from 'styled-components';

import lightingColors from '../styles/lighting';

const GlobalRoot = styled.div({
	width: '100%',
	overflow: 'auto',
	backgroundColor: lightingColors.background.light,
});

const StyledLayoutRoot = styled.div({
	backgroundColor: lightingColors.background.light,
});

interface LayoutRootProps {
	className?: string;
}
export const LayoutRoot: React.FC<LayoutRootProps> = props => (
	<GlobalRoot>
		<StyledLayoutRoot {...props} />
	</GlobalRoot>
);
