import { globalStyle } from './global';
import { lightingColors, theme, thingosColors } from './theme';

export { theme } from './theme';
export const Theme = {
	globalStyle,
	lightingColors,
	thingosColors,
	theme,
};
