/**
 * FooterSocial
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import * as React from 'react';
import styled from 'styled-components';

import { SocialIcon, SocialIconKind } from '../Icons/SocialIcons';
import { theme } from '../Theme/theme';

const IconLink = styled.a({
	padding: '1rem',
	'& svg': {
		color: `${theme.text.primary} !important`,
	},
	'& svg:hover': {
		color: `${theme.highlight.main} !important`,
	},
});

interface SocialLinkProps {
	url: string;
	icon: SocialIconKind;
}
export const FooterSocialLink: React.FC<SocialLinkProps> = props => {
	return (
		<IconLink target="_blank" rel="noreferrer" href={props.url}>
			<SocialIcon kind={props.icon} color={true as any} />
		</IconLink>
	);
};
