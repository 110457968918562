/**
 * store hooks
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { useContext } from 'react';

import { StoreContext } from '../stores';

export const useMenuStore = () => useContext(StoreContext).menuStore;
