/**
 * Mesh lighting layout
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { Helmet } from 'gatsby-plugin-react-i18next/dist/Helmet';
import 'modern-normalize';
import * as React from 'react';
import styled from 'styled-components';
import 'typeface-inter';
import 'typeface-roboto';

import { Theme } from '@thingos/thingos-homepage-shared';

import { LayoutRoot } from '../components/LayoutRoot';
import { LightingHeader as Header } from '../components/LightingHeader';
import '../styles/index.css';

import BackgroundImg from '../svgs/hero-wave-cropped.svg';

const GlobalThemeStyle = Theme.globalStyle(Theme.lightingColors);

const MainLayout = styled.main({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	position: 'relative',
	marginTop: '5.5rem',
	'&:before': {
		'@media screen and (max-width: 600px)': {
			backgroundImage: 'none',
		},
		content: '""',
		display: 'block',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		opacity: 0.3,
		backgroundImage: `url('${BackgroundImg}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top -100px right 0',
		backgroundSize: '100% auto',
		backgroundColor: 'transparent',
	},
});

interface ILayout {
	tabName?: string;
}
export const IndexLayout: React.FC<ILayout> = props => {
	const { tabName, children } = props;
	return (
		<LayoutRoot>
			<GlobalThemeStyle />
			<Helmet>
				<title>{tabName ?? 'Meshlighting'}</title>
				<meta name="description" content={tabName ?? 'Meshlighting website'} />
				<link rel="icon" href="favicon.ico" />
			</Helmet>
			<Header title="Mesh-lighting" />
			<MainLayout>{children}</MainLayout>
		</LayoutRoot>
	);
};
