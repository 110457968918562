import { FooterBar } from './FooterBar';
import { FooterContainer } from './FooterContainer';
import { FooterSiteMap } from './FooterSiteMap';
import { FooterSocialLink } from './FooterSocialLink';

export const Footer = {
	Container: FooterContainer,
	SocialLink: FooterSocialLink,
	FooterBar: FooterBar,
	SiteMap: FooterSiteMap,
};
