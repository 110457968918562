/**
 * stores
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { createContext } from 'react';

import { MenuStore } from './menuStore';

export const stores = {
	menuStore: new MenuStore(),
};

export const StoreContext = createContext(stores);
