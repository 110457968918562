import * as React from 'react';

import type { WebIconProps } from '@thingos/design-icons';
import EmailIcon from '@thingos/design-icons/web/services/email';
import FacebookIcon from '@thingos/design-icons/web/services/facebook';
import LinkedInIcon from '@thingos/design-icons/web/services/linkedIn';
import TwitterIcon from '@thingos/design-icons/web/services/twitter';
import YoutubeIcon from '@thingos/design-icons/web/services/youtube';

export type SocialIconKind =
	| 'services/email'
	| 'services/linkedIn'
	| 'services/youtube'
	| 'services/twitter'
	| 'services/facebook';

interface IProps extends Omit<WebIconProps, 'kind'> {
	kind: SocialIconKind;
}
export const SocialIcon: React.FC<IProps> = props => {
	const { kind, ...restProps } = props;
	switch (props.kind) {
		case 'services/email':
			return <EmailIcon {...restProps} />;
		case 'services/linkedIn':
			return <LinkedInIcon {...restProps} />;
		case 'services/twitter':
			return <TwitterIcon {...restProps} />;
		case 'services/youtube':
			return <YoutubeIcon {...restProps} />;
		case 'services/facebook':
			return <FacebookIcon {...restProps} />;
		default:
			return null;
	}
};
