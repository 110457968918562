import * as React from 'react';
import styled from 'styled-components';

import lightingColors from '../styles/lighting';

const StyledPage = styled.div<{ padding?: string }>(props => ({
	display: 'block',
	position: 'relative',
	'@media screen and (max-width: 600px)': {
		padding: props.padding ? props.padding : '0 0.4rem',
	},
}));

interface PageProps {
	sectionBackground?: string;
	id?: string;
	padding?: string;
}

export const Page: React.FC<PageProps> = props => {
	const { children, sectionBackground, id, padding } = props;

	return (
		<StyledPage
			style={
				sectionBackground === 'lightBackground'
					? { background: lightingColors.background.light }
					: sectionBackground === 'darkBackground'
					? { backgroundColor: lightingColors.background.dark }
					: { backgroundColor: `hsl(0deg 0% 100% / 2%)` }
			}
			id={id}
			padding={padding}
		>
			{children}
		</StyledPage>
	);
};
