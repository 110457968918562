import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../Text';
import { theme } from '../Theme';


export const FooterLogoImg = styled.img({
	height: 30,
	'@media only screen and (max-width: 600px)': {
		marginBottom: '1rem',
	},
});

const FooterBarContainerOuter = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	backgroundColor: theme.inverted.background.dark,
});

const FooterBarContainerInner = styled.div({
	display: 'flex',
	flex: 1,
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '1rem',
	maxWidth: '60rem',
	'@media only screen and (max-width: 600px)': {
		flexDirection: 'column',
		alignItems: 'flex-start',
		maxWidth: '100%',
	},
});

export const FooterBarContainer: React.FC = props => {
	return (
		<FooterBarContainerOuter>
			<FooterBarContainerInner {...props}></FooterBarContainerInner>
		</FooterBarContainerOuter>
	);
};

interface FooterBarLinkProps {
	to: string;
}
export const FooterBarLink: React.FC<FooterBarLinkProps> = props => {
	const { children, to } = props;
	return (
		<Text.Footnote>
			<Link to={to} style={{ color: theme.inverted.text.primary }}>
				{children}
			</Link>
		</Text.Footnote>
	);
};

export const FooterBarText: React.FC = props => {
	return <Text.Footnote style={{ color: theme.inverted.text.primary }} {...props} />;
};

export const FooterBar = {
	Logo: FooterLogoImg,
	Container: FooterBarContainer,
	Link: FooterBarLink,
	Text: FooterBarText,
};
