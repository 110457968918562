type ColorMap = { [key: string]: unknown };
export function mapKeyToColor(colorMap: ColorMap, colorTheme: ColorMap): string[] {
	let result = [];
	for (const [key, value] of Object.entries(colorMap)) {
		if (typeof value === 'string') {
			const color = colorTheme[key];
			result.push(`${value.slice(4, -1)}: ${color};`);
		} else {
			const subResult = mapKeyToColor(colorMap[key] as ColorMap, colorTheme[key] as ColorMap);
			result.push(...subResult);
		}
	}
	return result;
}
