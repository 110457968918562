import { createGlobalStyle } from 'styled-components';

import { ColorTheme, theme } from './theme';
import { mapKeyToColor } from './utils';

export function globalStyle(colorTheme: ColorTheme) {
	const colorMap = mapKeyToColor(theme, colorTheme).join('\n');
	return createGlobalStyle`
		html {
			${colorMap}
		}
	`;
}
