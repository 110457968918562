import styled from 'styled-components';

import { theme } from '../Theme/theme';

export * from './Paragraph';

const Title = styled.div({
	marginTop: '6rem',
	marginBottom: '4rem',
});

const HeaderH1 = styled.h1({});

const HeaderH2 = styled.h2({});

const HeaderH3 = styled.h3({});

const HeaderH4 = styled.h4({});

const Subtitle = styled.h5({});
const Subtitle2 = styled.h6({
	fontSize: '1rem',
});

const Body = styled.p({});

const Footnote = styled.p({
	marginBottom: 0,
});

const Link = styled.a({
	textDecoration: 'none !important',
	'&:hover': {
		color: theme.highlight.main,
	},
});

export const Text = {
	HeaderH1,
	HeaderH2,
	HeaderH3,
	HeaderH4,
	Subtitle,
	Subtitle2,
	Body,
	Body2: Body,
	Footnote,
	Link,
	Title,
};
