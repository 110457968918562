/**
 * media queries
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { useMedia as useReactMedia } from 'react-media';

const GLOBAL_MEDIA_QUERIES = {
	small: '(max-width: 599px)',
	medium: '(min-width: 600px) and (max-width: 1199px)',
	large: '(min-width: 1200px)',
};

export const useMedia = () => useReactMedia({ queries: GLOBAL_MEDIA_QUERIES });
