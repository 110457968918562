/**
 * FooterContainer
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import styled from 'styled-components';

export const FooterContainer = styled.div({
	alignItems: 'center',
	textAlign: 'center',
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	marginBottom: '3rem',
});
