import { Link } from 'gatsby-plugin-react-i18next';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import styled from 'styled-components';

import { useMedia } from '../hooks/media';
import { useMenuStore } from '../hooks/stores';
import lightingColors from '../styles/lighting';
import { dimensions } from '../styles/variables';
import { Container } from './Container';
import { LightingMenu } from './LightingMenu';

import Logo from '../svgs/mesh-lighting-logo.svg';

const HeaderStyled = styled.header<{ isMenuOpen?: boolean }>(props => ({
	position: 'fixed',
	top: 0,
	width: '100%',
	zIndex: 1001,
	padding: `1rem ${dimensions.containerPadding}rem`,
	transform: 'translate3d(0,0,0)',
	transition: '0.5s ease',
	backgroundColor: `${lightingColors.background.light}`,
	paddingTop: '3rem',
	'@media screen and (max-width: 600px)': {
		height: props.isMenuOpen ? '100%' : 'auto',
		display: props.isMenuOpen ? 'flex' : 'block',
		flex: props.isMenuOpen ? '1' : 'none',
		flexDirection: 'column',
		paddingTop: '2rem',
	},
}));

const HeaderInner = styled(Container)<{ isMenuOpen?: boolean }>(props => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	margin: props.isMenuOpen ? '0' : 'auto',
	height: !props.isMenuOpen ? '100%' : 'auto',
	maxHeight: '2.4rem',
}));

const HomepageLink = styled(Link)({
	'&:hover': {
		textDecoration: 'none',
	},
	'&:focus': {
		textDecoration: 'none',
	},
});

const HeaderLogo = styled.img({
	height: 30,
});

const MenuButton = styled.button({
	border: 'none',
	background: 'none',
	color: '#fff',
	'&:focus': {
		outline: 'none',
	},
});

interface HeaderProps {
	title: string;
}

export const LightingHeader: React.FC<HeaderProps> = observer(() => {
	const menuStore = useMenuStore();
	const media = useMedia();

	return (
		<HeaderStyled isMenuOpen={menuStore.isMenuOpen}>
			<HeaderInner isMenuOpen={menuStore.isMenuOpen}>
				<HomepageLink to="/">
					<HeaderLogo src={Logo} alt="logo" />
				</HomepageLink>
				{media.small && (
					<MenuButton role="button" onClick={menuStore.onPressToggleMenu}>
						{menuStore.isMenuOpen ? <FiX size="24px" /> : <FiMenu size="24px" />}
					</MenuButton>
				)}
				{!media.small && <LightingMenu />}
			</HeaderInner>
			{menuStore.isMenuOpen && <LightingMenu />}
		</HeaderStyled>
	);
});
