import { makeAutoObservable } from 'mobx';

export class MenuStore {
	public isMenuOpen = false;

	constructor() {
		makeAutoObservable(this);
	}

	public setMenuOpen = (isMenuOpen: boolean) => {
		this.isMenuOpen = isMenuOpen;
	};

	public onPressToggleMenu = () => {
		this.setMenuOpen(!this.isMenuOpen);
	};
}
